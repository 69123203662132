import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch, faSort } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table } from '@themesberg/react-bootstrap';
import http from "../http";
import Navbar from "../components/Navbar";

export default () => {
  const [transactions, setTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isloading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [users, setUsers] = useState([]);
  const [sortOrder, setSortOrder] = useState("desc");

  useEffect(() => {
    fetchPackages();
    fetchUsers();

    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const response = await http.get('/admin/payments');
        setLoading(false);  
        setTransactions(response.data?.data?.reverse());
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
      setLoading(false);
    };

    fetchTransactions();
  }, []);

  const fetchPackages = async () => {
    const response = await http.get('/admin/packages');
    console.log('packages', response.data?.data);
    setPackages(response.data?.data);
  };

  const fetchUsers = async () => {
    const response = await http.get('/admin/users');
    console.log('users', response.data?.data);
    setUsers(response.data?.data);
  };

  const getPackageName = (packageId) => {
    return packages.find(pkg => pkg.id === packageId);
  };

  const getUserName = (userId) => {
    let user = users.find(user => user.id === userId);
    if (user) {
      return user?.firstName + ' ' + user?.lastName;
    }
    return 'N/A';
  };

  const filteredTransactions = transactions?.filter(transaction =>
    
    transaction?.strip_payment_id?.toLowerCase()?.includes(searchTerm?.toLowerCase())



    
  );

  return (
    <>
      {isloading ? <div className="ReactLoader d-flex pt-12 justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div> : ''}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="start">
          <h4>Transactions</h4>
        </div>
        <div className="end d-inline-flex">
          <div className="TopButton">
          </div>
          <Navbar title="Overview" />
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search by Payment ID" onChange={e => setSearchTerm(e.target.value)} />
            </InputGroup>
          </Col>
        </Row>
      </div>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>User ID</th>
            <th>Amount</th>
            <th>Package ID</th>
            <th>Payment Status</th>
            <th>Stripe Payment ID</th>
            <th>Payment Type</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {filteredTransactions.map((transaction, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{getUserName(transaction?.user_id) || 'N/A'}</td>
              <td>${transaction?.amount}</td>
              <td>{getPackageName(transaction?.package_id)?.title || 'N/A'}</td>
              <td>{transaction?.payment_status}</td>
              <td>{transaction?.strip_payment_id}</td>
              <td>{transaction.payment_type}</td>
              <td>{new Date(transaction?.createdAt).toLocaleDateString()}</td>
              <td>{new Date(transaction?.updatedAt).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
